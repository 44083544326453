.rev-head {
  /* display: flex; */
  /* justify-content:space-between; */
  border-bottom: 1.5px solid #f5f5f5;
  margin-top: 20px;
  padding-bottom: 10px;
  font-size: 24px;
}

.revh-head {
  display: flex;
  justify-content: space-between;
  border-bottom: 1.5px solid #f5f5f5;
  margin-top: 20px;
  padding-bottom: 10px;
  font-size: 32px;
  align-items: center;
  margin: 20px 20px 15px 20px;
}
.date {
  display: flex;
}

.txt {
  display: flex;
  gap: 10px;
}
.txt span:active,
span:focus {
  color: #28d1ff;
  border-bottom: 2px solid #28d1ff;
}
.r-head {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-block-start: 10px;
  padding-block-end: 15px;
  margin-block-end: 20px;
  border-bottom: 1px solid #ebebeb;
}
.amt {
  font-size: 20px;
  font-weight: 700;
}
.lft {
  display: flex;
  align-items: center;
  gap: 20px;
}
.date {
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #333333;
}
.date a {
  text-decoration: none;
}
.cal {
  color: #333333;
  display: flex;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  align-items: center;
  /* box-shadow: 2px 1px 4px 1px rgba(201, 201, 201, 0.47); */
  gap: 10px;
  text-decoration: none;
  background: #f9f9f960;
  padding: 5px 10px;
  height: 16px;
}
.cal p,
.csv p {
  font-size: 13px;
}
.csv {
  display: flex;
  background: #28d1ff;
  color: #fff;
  align-items: center;
  height: 22px;
  border-radius: 4px;
  gap: 10px;
  padding: 4px 10px;
}

.revenues {
  list-style: none;
  background: #28d1ff;
  border-radius: 6px;
  /* border: 1px solid #28d1ff; */
  display: grid;
  align-items: center;
  grid-template-columns: repeat(4, 1fr);
  margin: 20px 20px 30px 20px;
  height: 150px;
  /* margin-bottom: 30px; */
}
.cal img,
.csv img {
  height: 10px;
}
.revenue {
  padding: 30px;
  background: #28d1ff;
  color: #fff;
  border-right: 2px solid #fff;
  display: flex;
  /* height: 100%; */
  flex-direction: column;
  gap: 10px;
  align-items: center;
}
.rv {
  background: #28d1ff;
  color: #fff;
  /* border-right: 2px solid #FFF; */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 10px;
}

.chart-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
}

.left-heading {
  display: flex;
  align-items: center;
  gap: 20px;
  list-style: none;
}
.left-heading p {
  font-size: 20px;
}
.left-heading button {
  margin-top: 2px;
  background: #28d1ff;
  border: 2px solid #28d1ff;
  /* padding: 3px; */

  height: 6px;
}
.left-heading_span {
  font-size: 12px;
  font-weight: 470;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.right-heading {
  color: #28d1ff;
}

.apexcharts-legend.apx-legend-position-right {
  justify-content: center !important;
  gap: 10px !important;
  margin-left: 20px !important;
}
