.client {
  /* display: flex; */
  border: 3px solid red;
}
/* .container {
  min-height: 100vh;
} */
.C-container {
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  background: #fff;
  padding: 20px;
  border-radius: 6px;
  margin: 20px;
}
.client-header {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-bottom: 1.3px solid #f5f5f5;
  padding: 20px;
}
.client-header .spanA {
  font-weight: 600;
  font-size: 20px;
}
.client-header .p {
  font-size: 10px;
}

.search {
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding: 20px;
}
.search input {
  position: relative;
  padding: 10px 30px;
  border: 1px solid #d8d8d8;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
}
.s-icon {
  position: absolute;
  bottom: 38%;
  right: 22%;
  z-index: 1;
  cursor: pointer;
}
.search input::placeholder {
  padding: 8px;
}
.s-icon:focus-visible {
  visibility: hidden;
}
