.table{
    border-collapse: collapse;
    font-size: 11.5px;
    width: 100%;
}
.table th{
    font-weight: 500;
    text-align: left;
    font-size: 10px;
    padding: 10px;
    color: #fff;
    background-color:#28D1FF;
 }
.table tr:nth-child(odd) {
    background-color:#f6f6f6;
}
.table td{
    padding: 10px;
    font-weight: 500;
    font-size: 11px;
}