.table{
    border-collapse: collapse;
    font-size: 11.5px;
    width: 100%;
}
.table th{
    font-weight: 500;
    text-align: left;
    font-size: 10px;
    padding: 10px;
    color: #fff;
    background-color:#28D1FF;
 }
.table tr:nth-child(odd) {
    background-color:#f6f6f6;
}
.table td{
    padding: 10px;
    font-weight: 500;
    font-size: 11px;
}

.services-rev{
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 10px;
}
.rev-input{
    float: right;
    padding: 10px;
    /* float: right; */
    /* display: flex;
    /* justify-content: flex-end; */ 
}

/* .services-table{
} */

.pie{
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    background: #fff;
    margin-block: 50px;
    padding: 10px 20px;
    border-radius: 6px;
    margin: 20px;
}

.pie-header{
    display: flex;
    align-items: center;
    justify-content:space-between;
    padding-block: 20px ;
}

.select{
    border: 1px solid #b8b8b8;
    border-radius: 4px;
    padding: 4px;

}

.service-t:active{
border-bottom: 2px solid #28D1FF;
color: #28D1FF;
font-size: bold;
cursor: pointer;
}
