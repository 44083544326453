.client-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1.3px solid #f5f5f5;
  margin-bottom: 30px;
}

.transaction-header {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.transaction-header .spanA {
  font-weight: 600;
  font-size: 20px;
}
.transaction-header .p {
  font-size: 10px;
}
.T-right {
  display: flex;
  gap: 10px;
  align-items: center;
}
.T-right .limit {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.T-right .limit p {
  margin: 0;
  font-size: 16px;
  margin-left: 4px;
}
.T-right .limit select {
  padding: 6px;
  border: 1px solid #d6d6d6;
  border-radius: 6px;
  color: #323232;
}

.cards {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  gap: 20px;
  text-decoration: none;
}

.input {
  display: flex;
  align-items: center;
  gap: 10px;
}
.inp .cards a {
  text-decoration: none;
  color: #333;
}
.cards a:hover {
  border-bottom: 2px solid #28d1ff;
  color: #28d1ff;
  font-weight: 550;
}
.input-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.input label {
  font-size: 16px;
  margin-left: 8px;
}

.input input {
  padding: 6px;
  border-radius: 6px;
  border: 1px solid #d6d6d6;
}
.btn-icon {
  padding-top: 20px;
}

.csv {
  margin-block-start: 20px;
}
