@import url("https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Mulish:ital,wght@0,200;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0;
  font-family: "Karla", sans-serif;
  /* font-family: 'Mulish', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .container{
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
} */
/* .sidebar {

  flex: 0.2;
} */

/* .main-container {
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  background: #fff;
  padding: 10px;
  border-radius: 6px;
  margin: 20px;
} */
