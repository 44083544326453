.homepage {
}

.img-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 2px;
  gap: 18px;
}
.Admin {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 5px;
}
.span1 {
  font-weight: 600;
  font-size: 22px;
}
.span2 {
  color: #198754;
}
.img-admin {
  height: 30px;
}

.top-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px;
  border-bottom: 1px solid #f5f5f5;
}
.right-text {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  font-size: 13px;
}

.right-text p:hover {
  /* text-decoration: underline; */
  border-bottom: 2px solid #28d1ff;
  font-weight: bold;
  color: #28d1ff;
}

.wsection {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(4, 1fr);
  padding: 20px;
}

.widgets {
  background: #fff;
  cursor: pointer;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
}
.top-content {
  padding-block-start: 10px;
  padding-inline: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.wTexts {
  display: flex;
  flex-direction: column;
}
.first-img {
  height: 38px;
}
.w-img {
  height: 85px;
}
.t-desc {
  font-size: 12px;
}
.w-image {
  width: 100%;
  height: 88px;
  border-radius: 12px;
}

.B-container {
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  background: #fff;
  padding: 10px;
  border-radius: 6px;
  margin: 20px;
}
